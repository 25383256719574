/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Svg from 'react-inlinesvg';
import styles from '../scss/components/header.module.scss';
import SpotifyLogo from '../images/svgs/logo_spotify.svg';
import sendDataLayer from '../utils/sendDataLayer';

const Header = () => (
  <header className={styles.header}>
    <div className={styles.header__wrapper}>
      <a
        onClick={event => {
          event.preventDefault();
          window.open('https://www.spotify.com/uk/');
          sendDataLayer('gtm.event', 'Pageview', 'Header Spotify Logo', '/');
        }}
        href="#"
        className={styles.header__logo}
      >
        <Svg src={SpotifyLogo} />
      </a>
    </div>
  </header>
);

export default Header;
