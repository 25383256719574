import React from 'react';
// import PropTypes from 'prop-types';
// import { graphql } from 'gatsby';

import { Router } from '@reach/router';
import RouteValidator from '../components/RouteValidator';
import Layout from '../components/layout';
import SEO from '../components/seo';
import cms from '../cms.json';
import PayoffIndex from '../components/Payoff/index';

const Index = ({ location }) => (
  <Layout>
    <SEO title="Spotify HIIT" pageUrl="/your-playlist" twitterUsername="TwitterUsername" />
    <Router>
      <RouteValidator path="your-playlist" cms={cms} component={PayoffIndex} location={location} />
    </Router>
  </Layout>
);

export default Index;
