import React, { useState, useEffect } from 'react';
import Svg from 'react-inlinesvg';
import Lottie from 'react-lottie';
import sendDataLayer from '../../../utils/sendDataLayer';

import styles from '../../../scss/components/exercise.module.scss';
import Mute from '../../../images/svgs/icon_mute.svg';
import Muted from '../../../images/svgs/icon_muted.svg';
import ProgressBar from './progressBar';

// Exercises
import HighKnees from '../../../animationData/knees-up.json';
import Dips from '../../../animationData/dips.json';
import MountainClimbers from '../../../animationData/mountain-climbers.json';
import StarJumps from '../../../animationData/star-jumps.json';
import PressUp from '../../../animationData/press-up.json';
import Squats from '../../../animationData/squats.json';
import BicycleCrunches from '../../../animationData/bicycle-crunches.json';
import Crunches from '../../../animationData/crunches.json';
import SquatJumps from '../../../animationData/squat-jumps.json';
import BumKicks from '../../../animationData/bum-kicks.json';
import Plank from '../../../animationData/plank.json';
import GluteBridge from '../../../animationData/glute-bridge.json';
import Lunges from '../../../animationData/lunges.json';
import LegRaise from '../../../animationData/leg-raises.json';
import Burpees from '../../../animationData/burpees.json';

import outdoorBg from '../../../images/svgs/background/outdoor.svg';
import indoorBg from '../../../images/svgs/background/indoor.svg';
import spaceBg from '../../../images/svgs/background/space.svg';

const ExerciseIndex = ({ playlist, count, mute, muted, duration, exercise }) => {
  const [data, setData] = useState();
  const noOfExercises = playlist.exercises.length;
  const exerciseName = playlist.exercises[count].slug;
  const exerciseNumber = count + 1;
  const location = localStorage.getItem('location');
  const level = localStorage.getItem('level');
  const [speed, setSpeed] = useState(1);

  const HighKneesExercise = {
    loop: true,
    autoplay: true,
    animationData: HighKnees,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const DipsExercise = {
    loop: true,
    autoplay: true,
    animationData: Dips,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const MountainClimbersExercise = {
    loop: true,
    autoplay: true,
    animationData: MountainClimbers,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const StarJumpsExercise = {
    loop: true,
    autoplay: true,
    animationData: StarJumps,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const PressUpExercise = {
    loop: true,
    autoplay: true,
    animationData: PressUp,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const SquatsExercise = {
    loop: true,
    autoplay: true,
    animationData: Squats,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const BicycleCrunchesExercise = {
    loop: true,
    autoplay: true,
    animationData: BicycleCrunches,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const CrunchesExercise = {
    loop: true,
    autoplay: true,
    animationData: Crunches,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const SquatJumpsExercise = {
    loop: true,
    autoplay: true,
    animationData: SquatJumps,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const BumKicksExercise = {
    loop: true,
    autoplay: true,
    animationData: BumKicks,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const PlankExercise = {
    loop: true,
    autoplay: true,
    animationData: Plank,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const GluteBridgeExercise = {
    loop: true,
    autoplay: true,
    animationData: GluteBridge,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const LungesExercise = {
    loop: true,
    autoplay: true,
    animationData: Lunges,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const LegRaiseExercise = {
    loop: true,
    autoplay: true,
    animationData: LegRaise,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const BurpeesExercise = {
    loop: true,
    autoplay: true,
    animationData: Burpees,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const speedConvert = level => {
    switch (level) {
      case '2':
        return setSpeed(1.3);
      case '3':
        return setSpeed(1.6);
      default:
        return setSpeed(1);
    }
  };

  useEffect(() => {
    speedConvert(level);
    setData(exerciseName);
    sendDataLayer('gtm.event', 'Pageview', `Exercise ${exerciseNumber}`, '/your-playlist');
  }, []);

  return (
    <div className={styles.exercise}>
      <div className={styles.exercise__titleWrapper}>
        <div className={`${styles.exercise__counter} color--tangerine`}>
          <p className="h3">{count + 1}</p>
          <p className="h3">/</p>
          <p className="h3">{noOfExercises}</p>
        </div>
        <h1 className={`${styles.exercise__title} h2 color--tangerine`}>
          {playlist.exercises[count].name}
        </h1>
      </div>

      <p className="mute-icon" type="button" onClick={mute} onKeyDown={mute}>
        {muted === 'false' && <Svg src={Mute} />}
        {muted === 'true' && <Svg src={Muted} />}
      </p>
      <div className={styles.exercise__animationWrapper}>
        <div className={styles.exercise__slideUp}>
          {location === 'outer space' && <Svg className={styles.exercise__bg} src={spaceBg} />}
          {location === 'outside' && <Svg className={styles.exercise__bg} src={outdoorBg} />}
          {location === 'inside' && <Svg className={styles.exercise__bg} src={indoorBg} />}

          {data === 'high-knees' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={HighKneesExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'dips' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={DipsExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'mountain-climbers' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={MountainClimbersExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'star-jumps' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={StarJumpsExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'press-ups' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={PressUpExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'squats' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={SquatsExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'bicycle-crunches' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={BicycleCrunchesExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'crunches' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={CrunchesExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'squat-jumps' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={SquatJumpsExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'bum-kicks' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={BumKicksExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'plank' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={PlankExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'glute-bridge' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={GluteBridgeExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'lunges' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={LungesExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'leg-lifts' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={LegRaiseExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
          {data === 'burpees' && (
            <div className={styles.exercise__lottieAnimation}>
              <Lottie options={BurpeesExercise} speed={speed} useSubFrames={false} />
            </div>
          )}
        </div>
      </div>
      <ProgressBar duration={duration} playlist={playlist} count={count} exercise={exercise} />
    </div>
  );
};

export default ExerciseIndex;
