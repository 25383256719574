import React, { useState, useEffect } from 'react';
import Svg from 'react-inlinesvg';
import Lottie from 'react-lottie';
import styles from '../../../scss/components/cooldown.module.scss';
import sendDataLayer from '../../../utils/sendDataLayer';

import Mute from '../../../images/svgs/icon_mute.svg';
import Muted from '../../../images/svgs/icon_muted.svg';
import ProgressBar from './progressBar';
import Counter from './counter';

// Exercises
import HighKnees from '../../../animationData/knees-up.json';
import Dips from '../../../animationData/dips.json';
import MountainClimbers from '../../../animationData/mountain-climbers.json';
import StarJumps from '../../../animationData/star-jumps.json';
import PressUp from '../../../animationData/press-up.json';
import Squats from '../../../animationData/squats.json';
import BicycleCrunches from '../../../animationData/bicycle-crunches.json';
import Crunches from '../../../animationData/crunches.json';
import SquatJumps from '../../../animationData/squat-jumps.json';
import BumKicks from '../../../animationData/bum-kicks.json';
import Plank from '../../../animationData/plank.json';
import GluteBridge from '../../../animationData/glute-bridge.json';
import Lunges from '../../../animationData/lunges.json';
import LegRaise from '../../../animationData/leg-raises.json';
import Burpees from '../../../animationData/burpees.json';

const CooldownIndex = ({ playlist, count, mute, muted, duration, exercise }) => {
  const [data, setData] = useState();
  const [showCountdown, setShowCountdown] = useState('false');
  const [countdownEnded, setCountdownEnded] = useState('false');
  const level = localStorage.getItem('level');
  const [speed, setSpeed] = useState(1);
  const restNumber = count + 1;

  const speedConvert = level => {
    switch (level) {
      case '2':
        return setSpeed(1.3);
      case '3':
        return setSpeed(1.6);
      default:
        return setSpeed(1);
    }
  };

  const HighKneesExercise = {
    loop: true,
    autoplay: true,
    animationData: HighKnees,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const DipsExercise = {
    loop: true,
    autoplay: true,
    animationData: Dips,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const MountainClimbersExercise = {
    loop: true,
    autoplay: true,
    animationData: MountainClimbers,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const StarJumpsExercise = {
    loop: true,
    autoplay: true,
    animationData: StarJumps,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const PressUpExercise = {
    loop: true,
    autoplay: true,
    animationData: PressUp,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const SquatsExercise = {
    loop: true,
    autoplay: true,
    animationData: Squats,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const BicycleCrunchesExercise = {
    loop: true,
    autoplay: true,
    animationData: BicycleCrunches,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const CrunchesExercise = {
    loop: true,
    autoplay: true,
    animationData: Crunches,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const SquatJumpsExercise = {
    loop: true,
    autoplay: true,
    animationData: SquatJumps,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const BumKicksExercise = {
    loop: true,
    autoplay: true,
    animationData: BumKicks,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const PlankExercise = {
    loop: true,
    autoplay: true,
    animationData: Plank,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const GluteBridgeExercise = {
    loop: true,
    autoplay: true,
    animationData: GluteBridge,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const LungesExercise = {
    loop: true,
    autoplay: true,
    animationData: Lunges,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const LegRaiseExercise = {
    loop: true,
    autoplay: true,
    animationData: LegRaise,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };
  const BurpeesExercise = {
    loop: true,
    autoplay: true,
    animationData: Burpees,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  useEffect(() => {
    if (duration < 5) {
      setShowCountdown('true');
      setCountdownEnded(false);
    } else {
      setShowCountdown('false');
      const countDownEnded = setTimeout(() => {
        setCountdownEnded(true);
      }, 1000);
      return () => clearTimeout(countDownEnded);
    }
  }, [duration]);

  useEffect(() => {
    speedConvert(level);

    if (count + 1 !== playlist.length && playlist.exercises[count + 1]) {
      setData(playlist.exercises[count + 1].slug);
    } else {
      setData('high-knees');
    }
  }, []);

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', `Rest ${restNumber}`, '/your-playlist');
  }, []);

  return (
    <div
      className={`${styles.cooldown} ${showCountdown === 'false' ? 'bg--green' : 'bg--tangerine'}`}
    >
      <p
        className={`mute-icon ${showCountdown === 'true' ? 'mute-icon--green' : ''}`}
        type="button"
        onClick={mute}
        onKeyDown={mute}
      >
        {muted === 'false' && <Svg src={Mute} />}
        {muted === 'true' && <Svg src={Muted} />}
      </p>
      {showCountdown === 'false' && (
        <div className={styles.cooldown__lockup}>
          <div className={`${styles.cooldown__wrapper}  ${countdownEnded ? '' : 'hide'}`}>
            <h1 className={`${styles.cooldown__title} color--tangerine`}>Rest</h1>
            {playlist.exercises[count + 1] && (
              <p className={`${styles.cooldown__subtitle} color--tangerine`}>
                <span> Up next -</span>
                <span className={`${styles.cooldown__exerciseName} text--bold`}>
                  {playlist.exercises[count + 1].name}
                </span>
              </p>
            )}
            <div className={styles.cooldown__content}>
              <div className={styles.cooldown__animation}>
                {data === 'high-knees' && (
                  <Lottie options={HighKneesExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'dips' && (
                  <Lottie options={DipsExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'mountain-climbers' && (
                  <Lottie options={MountainClimbersExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'star-jumps' && (
                  <Lottie options={StarJumpsExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'press-ups' && (
                  <Lottie options={PressUpExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'squats' && (
                  <Lottie options={SquatsExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'bicycle-crunches' && (
                  <Lottie options={BicycleCrunchesExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'crunches' && (
                  <Lottie options={CrunchesExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'squat-jumps' && (
                  <Lottie options={SquatJumpsExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'bum-kicks' && (
                  <Lottie options={BumKicksExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'plank' && (
                  <Lottie options={PlankExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'glute-bridge' && (
                  <Lottie options={GluteBridgeExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'lunges' && (
                  <Lottie options={LungesExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'leg-lifts' && (
                  <Lottie options={LegRaiseExercise} speed={speed} useSubFrames={false} />
                )}
                {data === 'burpees' && (
                  <Lottie options={BurpeesExercise} speed={speed} useSubFrames={false} />
                )}
              </div>
              <div className={styles.cooldown__factWrapper}>
                <p
                  className={`${styles.cooldown__question} text--center color--tangerine text--bold push-v--mobile--x-small`}
                >
                  Did you know?
                </p>
                <p className={`${styles.cooldown__fact} h5 color--tangerine text--center`}>
                  {playlist.exercises[count].fact.message}
                </p>
              </div>
            </div>
          </div>
          <ProgressBar duration={duration} playlist={playlist} count={count} exercise={exercise} />
        </div>
      )}
      {showCountdown === 'true' && (
        <Counter count={count + 1} playlist={playlist} muted={muted} mute={mute} />
      )}
    </div>
  );
};

export default CooldownIndex;
