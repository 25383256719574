import React, { useState, useEffect } from 'react';
import Svg from 'react-inlinesvg';
import { navigate } from 'gatsby';
import sendDataLayer from '../../../utils/sendDataLayer';
import styles from '../../../scss/components/playlist.module.scss';
import SpotifyLogo from '../../../images/svgs/logo_spotify.svg';
import Restart from '../../../images/svgs/icon_loop_back.svg';
import Wave from '../../../images/svgs/share_wave.svg';
import WaveDesktop from '../../../images/svgs/payoff_wave_desktop.svg';
import WaveTablet from '../../../images/svgs/payoff_wave_tablet.svg';
import WaveMobile from '../../../images/svgs/payoff_wave_mobile_V.svg';
import WaveMobileLandscape from '../../../images/svgs/payoff_wave_mobile_L.svg';
import MicRun from '../../../images/run_mic.png';
import Share from './share';

const PayoffIndex = ({ playlist, cms }) => {
  const connected = localStorage.getItem('connected');
  const [device, setDevice] = useState('desktop');
  const artist1 = playlist.exercises[0].track.artists;
  const artist2 = playlist.exercises[1].track.artists;
  const artist3 = playlist.exercises[2].track.artists;
  const artist4 = playlist.exercises[3].track.artists;
  const artist5 = playlist.exercises[4].track.artists;

  const restart = () => {
    localStorage.setItem('location', '');
    localStorage.setItem('level', 4);
    localStorage.setItem('duration', 14);
    localStorage.setItem('coach', null);
    localStorage.setItem('genres', []);
    localStorage.setItem('levelString', 'medium');
    localStorage.setItem('payoff', 'false');

    window.Howler.unload();

    navigate('/');

    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Start Again');
  };

  const deviceDetector = () => {
    if (window.innerWidth <= 767 && window.innerHeight > window.innerWidth) {
      setDevice('mobile');
    } else if (window.innerHeight < window.innerWidth && window.innerWidth < 900) {
      setDevice('mobile-landscape');
    } else if (window.innerWidth > 768 && window.innerWidth < 1025) {
      setDevice('tablet');
    } else {
      setDevice('desktop');
    }
  };

  const textTruncate = str => {
    const ending = '...';

    if (str.length > 13) {
      return str.substring(0, 15 - ending.length) + ending;
    }
    return str;
  };

  const handleClick = (event, link, label) => {
    event.preventDefault();
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', `${label}`);
    window.open(`${link}`);
  };

  useEffect(() => {
    deviceDetector();
    window.addEventListener('resize', deviceDetector);

    return () => window.removeEventListener('resize', deviceDetector);
  });

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', 'Your Playlist', '/your-playlist');
  }, []);

  return (
    <div className={styles.playlist}>
      <div className={styles.playlist__container}>
        <div
          className={`${styles.playlist__waveLarge} ${
            connected === 'true' ? `${styles.playlist__waveConnected}` : ''
          }`}
        >
          {device === 'desktop' && <Svg src={WaveDesktop} />}
          {device === 'tablet' && <Svg src={WaveTablet} />}
          {device === 'mobile' && <Svg src={WaveMobile} />}
          {device === 'mobile-landscape' && <Svg src={WaveMobileLandscape} />}
        </div>
        <div className={styles.playlist__wrapper}>
          <div className={styles.playlist__leftLockup}>
            <div
              className={`${styles.playlist__shareCard} push-v--mobile--medium push-v--desktop--xx-small`}
            >
              <Svg src={SpotifyLogo} className={styles.playlist__logo} />
              <div className={styles.playlist__wave}>
                <Svg src={Wave} />
              </div>
              <img className={styles.playlist__image} src={MicRun} alt="" />
              <div className={styles.playlist__content}>
                <h1 className={`${styles.playlist__title} h4 color--green`}>{playlist.name}</h1>
                <div className={styles.playlist__music}>
                  <p className="color--green">Pumped music by:</p>
                  <ul className={`${styles.playlist__artists} color--green h4`}>
                    <li>{textTruncate(artist1)}</li>
                    <li>{textTruncate(artist2)}</li>
                    <li>{textTruncate(artist3)}</li>
                    <li>{textTruncate(artist4)}</li>
                    <li>{textTruncate(artist5)}</li>
                  </ul>
                  <p className="color--green">and more...</p>
                </div>

                <a
                  className={`${styles.playlist__cta} cta cta--green color--green`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="#"
                  onClick={event =>
                    handleClick(event, 'https://www.Spotify.com/Pumped', 'Spotify.com/Pumped')
                  }
                >
                  Spotify.com/Pumped
                </a>
                <button
                  type="button"
                  onClick={restart}
                  className={`${styles.playlist__restart} button button--dark button--small button--icon`}
                >
                  Start again
                  <Svg src={Restart} />
                </button>
              </div>
            </div>
            <div
              className={`${connected === 'true' ? `${styles.playlist__connected}` : ''} ${
                styles.playlist__share
              } ${device === 'mobile-landscape' ? `${styles.playlist__landscape}` : ''}`}
            >
              <Share playlist={playlist} cms={cms} />
            </div>
          </div>
          <div
            className={`${styles.playlist__lockup} push-v--mobile--large push-v--desktop--xx-small`}
          >
            {connected === 'true' && (
              <div>
                <h1
                  className={`${styles.playlist__subtitle} h3 color--green push-v--mobile--small push-v--tablet--large`}
                >
                  {cms.playlist.play.title}
                </h1>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button button--light button--large"
                  href="#"
                  onClick={event =>
                    handleClick(event, localStorage.getItem('playlistUrl'), 'Get your playlist')
                  }
                >
                  {cms.playlist.play.cta.text}
                </a>
                <div className={styles.playlist__shareAltConnected}>
                  <Share playlist={playlist} cms={cms} />
                </div>
              </div>
            )}
            {connected === 'false' && (
              <div>
                <h1
                  className={`${styles.playlist__subtitle} h3 color--green push-v--mobile--small push-v--tablet--large`}
                >
                  {cms.playlist.join.title}
                </h1>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button button--light button--large"
                  href="#"
                  onClick={event => handleClick(event, cms.playlist.join.cta.url, 'Join Spotify')}
                >
                  {cms.playlist.join.cta.text}
                </a>
                <div className={styles.playlist__shareAlt}>
                  <Share playlist={playlist} cms={cms} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoffIndex;
