/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Svg from 'react-inlinesvg';
import sendDataLayer from '../../../utils/sendDataLayer';

import styles from '../../../scss/components/share.module.scss';
import Facebook from '../../../images/svgs/social_facebook.svg';
import Instagram from '../../../images/svgs/social_instagram.svg';
import Twitter from '../../../images/svgs/social_twitter.svg';

const ShareIndex = ({ playlist, cms }) => (
  <div className={`${styles.share} push-v--mobile--large`}>
    <p className="color--green push-v--mobile--x-small push-v--desktop--xx-small">
      Share your workout
    </p>
    <a
      href="#"
      onClick={event => {
        event.preventDefault();
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${playlist.share_url}`);
        sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Share Facebook');
      }}
    >
      <Svg src={Facebook} className={styles.share__icon} />
    </a>
    <a
      href="#"
      onClick={event => {
        event.preventDefault();
        window.location.href = playlist.instagram_share_url;
        sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Share Instagram');
      }}
    >
      <Svg src={Instagram} className={styles.share__icon} />
    </a>
    <a
      href="#"
      onClick={event => {
        event.preventDefault();
        window.open(
          `https://www.twitter.com/intent/tweet?url=${playlist.share_url}&text=I just pumped up my workout with Spotify. Start your free HIIT session at Spotify.com/Pumped`,
        );
        sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Share Twitter');
      }}
      className="icon-container"
    >
      <Svg src={Twitter} className={styles.share__icon} />
    </a>
  </div>
);

export default ShareIndex;
