import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();
// Provider
// Consumer

const Context = ({ children }) => {
  const [state, setState] = useState({
    connectToken: null,
    spotifyConnect: { status: 'idle' },
    playlist: null,
  });

  /* Add GlobalState Functions below */
  const updateGlobalState = newState => {
    const replace = { ...state, ...newState };
    setState(replace);
  };

  return (
    <AppContext.Provider value={{ ...state, updateGlobalState }}>{children}</AppContext.Provider>
  );
};

export default Context;
