/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Svg from 'react-inlinesvg';
// import PropTypes from 'prop-types'
import styles from '../scss/components/footer.module.scss';
import sendDataLayer from '../utils/sendDataLayer';

const Footer = () => {
  const links = [
    {
      text: 'Legal',
      label: 'footer legal',
      url: 'https://www.spotify.com/uk/legal/end-user-agreement/',
    },
    {
      text: 'Privacy',
      label: 'footer privacy',
      url: 'https://www.spotify.com/uk/legal/privacy-policy/',
    },
    {
      text: 'Cookies',
      label: 'footer cookies',
      url: 'https://www.spotify.com/uk/legal/cookies-policy/?language=en&country=gb',
    },
  ];

  const handleClick = (event, link, label) => {
    event.preventDefault();
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', `${label}`);
    window.open(`${link}`);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__linksWrapper}>
        <ul className={styles.footer__links}>
          {links.map(link => (
            <li key={link.text} className={styles.footer__linkWrapper}>
              <a
                onClick={event => handleClick(event, link.url, link.label)}
                href="#"
                className={styles.footer__label}
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>

        <div className={styles.footer__copyright}>
          <p className={styles.footer__label}>&copy;2020 Spotify</p>
          <p className={styles.footer__label}>United Kingdom</p>
        </div>
      </div>
    </footer>
  );
};

// Footer.propTypes = { cmsContent: PropTypes.objectOf(PropTypes.any).isRequired };

export default Footer;
