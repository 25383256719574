import React, { useState, useEffect } from 'react';
import Svg from 'react-inlinesvg';

import styles from '../../../scss/components/counter.module.scss';
import BicycleCrunches from '../../../images/svgs/exercises/bicycle_crunches.svg';
import BumKicks from '../../../images/svgs/exercises/bum_kicks.svg';
import Burpees from '../../../images/svgs/exercises/burpee.svg';
import Crunches from '../../../images/svgs/exercises/crunches.svg';
import Dips from '../../../images/svgs/exercises/dips.svg';
import GluteBridge from '../../../images/svgs/exercises/glute_bridge.svg';
import SquatJumps from '../../../images/svgs/exercises/jump_squats.svg';
import HighKnees from '../../../images/svgs/exercises/knee_up.svg';
import LegRaise from '../../../images/svgs/exercises/leg_ups.svg';
import Lunges from '../../../images/svgs/exercises/lunges.svg';
import MountainClimbers from '../../../images/svgs/exercises/mountain_climbers.svg';
import Plank from '../../../images/svgs/exercises/plank.svg';
import PressUp from '../../../images/svgs/exercises/press_up.svg';
import Squats from '../../../images/svgs/exercises/squats.svg';
import StarJumps from '../../../images/svgs/exercises/star_jump.svg';

const CounterIndex = ({ playlist, count, mute, muted }) => {
  const [counter, setCounter] = useState(3);
  const [data, setData] = useState();

  useEffect(() => {
    const timer = counter >= 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (playlist.exercises[count].slug) {
      setData(playlist.exercises[count].slug);
    } else {
      setData('high-knees');
    }
  }, []);

  return (
    <div className={styles.counter}>
      <div className={styles.counter__container}>
        <div className={`${styles.counter__titleWrapper} text--center`}>
          {count === 0 && <p className={`${styles.counter__subtitle} h5 color--green`}>Up first</p>}
          {count > 0 && <p className={`${styles.counter__subtitle} h5 color--green`}>Up next</p>}
          <h1
            className={`${styles.counter__title} color--green text--center push-v--mobile--medium`}
          >
            {playlist.exercises[count].name}
          </h1>
        </div>

        <div>
          {counter > 0 && (
            <h1 className={`${styles.counter__number} h1-huge color--green`}>{counter}</h1>
          )}
          {counter <= 0 && <h1 className="h1-huge color--green">GO!</h1>}
        </div>
      </div>

      <div className={styles.counter__animation}>
        {data === 'high-knees' && <Svg src={HighKnees} className={styles.counter__image} />}
        {data === 'dips' && <Svg src={Dips} className={styles.counter__image} />}
        {data === 'mountain-climbers' && (
          <Svg src={MountainClimbers} className={styles.counter__image} />
        )}
        {data === 'star-jumps' && <Svg src={StarJumps} className={styles.counter__image} />}
        {data === 'press-ups' && <Svg src={PressUp} className={styles.counter__image} />}
        {data === 'squats' && <Svg src={Squats} className={styles.counter__image} />}
        {data === 'bicycle-crunches' && (
          <Svg src={BicycleCrunches} className={styles.counter__image} />
        )}
        {data === 'crunches' && <Svg src={Crunches} className={styles.counter__image} />}
        {data === 'squat-jumps' && <Svg src={SquatJumps} className={styles.counter__image} />}
        {data === 'bum-kicks' && <Svg src={BumKicks} className={styles.counter__image} />}
        {data === 'plank' && <Svg src={Plank} className={styles.counter__image} />}
        {data === 'glute-bridge' && <Svg src={GluteBridge} className={styles.counter__image} />}
        {data === 'lunges' && <Svg src={Lunges} className={styles.counter__image} />}
        {data === 'leg-lifts' && <Svg src={LegRaise} className={styles.counter__image} />}
        {data === 'burpees' && <Svg src={Burpees} className={styles.counter__image} />}
      </div>
    </div>
  );
};

export default CounterIndex;
