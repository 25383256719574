/* eslint-disable no-unneeded-ternary */
/* eslint-disable object-shorthand */
import React, { useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { TweenMax, Sine } from 'gsap';
import Api from '../../utils/api';
import { AppContext } from '../../utils/context';
import styles from '../../scss/components/payoff.module.scss';
import sendDataLayer from '../../utils/sendDataLayer';

import Content from './Workout/content';
import Loader from '../loader';

const PayoffIndex = ({ cms }) => {
  const { connectToken, playlist, updateGlobalState } = useContext(AppContext);
  const [step, setWorkoutStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [checked, setIsChecked] = useState(false);
  const location = localStorage.getItem('location');
  const duration = localStorage.getItem('duration');
  const coach = localStorage.getItem('coach');
  const genres = localStorage.getItem('genres').split(',');
  const level = localStorage.getItem('levelString');

  const savePlaylist = currentPlaylist => {
    // console.log(currentPlaylist);

    const save = new Api({
      url: 'api/playlists/save',
      key: connectToken,
      method: 'POST',
    });

    save.submit({ id: currentPlaylist.id }).then(response => {
      localStorage.setItem('playlistUrl', response.playlist.url);
    });
  };

  const generate = () => {
    let generatedPlaylist;
    const createPlaylist = new Api({
      url: 'api/playlists/generator',
      key: connectToken ? connectToken : process.env.GATSBY_API_KEY,
      method: 'POST',
    });

    const params = {
      type: 'genres',
      genres: genres,
      intensity: level,
      duration: duration,
      coach: coach,
      place: location,
    };

    createPlaylist.submit(params).then(response => {
      generatedPlaylist = response.playlist;
      updateGlobalState({ playlist: generatedPlaylist });
      setIsChecked(true);

      if (connectToken) {
        savePlaylist(generatedPlaylist);
      }
    });
  };

  const setStep = () => {
    setLoader(true);
    setLoaded(true);
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Start Workout');
  };

  const goBack = () => {
    navigate('/connect');
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Go back Workout Page');
  };

  useEffect(() => {
    generate();
    localStorage.setItem('skip', 'false');
  }, []);

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', 'Time to HIIT it', '/your-playlist');

    TweenMax.to('.payoff', 0, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0,
    });
  }, []);

  return (
    <div
      className={`${styles.payoff} payoff ${
        localStorage.getItem('payoff') === 'true' ? `${styles.payoff__screen}` : ''
      }`}
    >
      {step === 0 && (
        <div className={`${styles.payoff__wrapper} ${loaded ? 'hide' : ''}`}>
          <div className={styles.payoff__stepWrapper}>
            <h1
              className={`${styles.payoff__title} color--green text--center push-v--mobile--small push-v--tablet--large push-v--desktop--x-large`}
            >
              {cms.payoff.title}
            </h1>
            <div className={checked ? '' : 'disabled'}>
              <button
                type="button"
                className={`${styles.payoff__button}  button button--light button--x-large push-v--mobile--small push-v--tablet--large push-v--desktop--x-large`}
                onClick={setStep}
              >
                {cms.payoff.ctaText}
              </button>
            </div>
            <button
              type="button"
              className={`${styles.payoff__button} cta cta--green button--x-large push-v--mobile--small push-v--tablet--large push-v--desktop--x-large`}
              onClick={goBack}
            >
              Go back
            </button>
          </div>
        </div>
      )}
      {loader && (
        <Loader
          setLoader={setLoader}
          setWorkoutStep={setWorkoutStep}
          generatedPlaylist={playlist}
        />
      )}
      {step === 1 && <Content setStep={setStep} generatedPlaylist={playlist} cms={cms} />}
    </div>
  );
};

export default PayoffIndex;
