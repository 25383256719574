import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import '../scss/styles.scss';

import Context from '../utils/context';
import Header from './Header';
import Footer from './footer';
import CookieBar from './CookieBar';
import favicon from '../images/favicon.png';

const Layout = ({ children }) => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  return (
    <div className="layout">
      <Context>
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        <Header />
        <main>{children}</main>
        <Footer />
        <CookieBar />
      </Context>
    </div>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  // cms: PropTypes.objectOf(PropTypes.any).isRequired,
};
