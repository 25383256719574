import React, { useEffect, useState } from 'react';
import { Sine, Power0, TimelineMax } from 'gsap';
import { Howl } from 'howler';
import Svg from 'react-inlinesvg';
import sendDataLayer from '../utils/sendDataLayer';
import styles from '../scss/components/loader.module.scss';
import Counter from './Payoff/Workout/counter';
import Mute from '../images/svgs/icon_mute.svg';
import Muted from '../images/svgs/icon_muted.svg';

const Loader = ({ setLoader, setWorkoutStep, generatedPlaylist }) => {
  const [showCountdown, setShowCountdown] = useState('false');
  const [muted, setMuted] = useState('false');
  let wakeLock = null;
  let introActiveCheck = null;

  const requestWakeLock = async () => {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
    } catch (err) {
      console.log('wake-lock failed');
    }
  };

  const introAudio = new Howl({
    src: generatedPlaylist.intro.audio,
    format: ['mp3'],
    preload: false,
    onload() {
      introAudio.play();
    },
    onplay() {
      if (introActiveCheck === null) {
        introActiveCheck = setInterval(() => {
          if (document.visibilityState === 'visible') {
            if (!introAudio.playing() && wakeLock === null) {
              introAudio.play();
              requestWakeLock();
            }
          } else {
            introAudio.pause();

            if (wakeLock !== null) {
              wakeLock.release().then(() => {
                wakeLock = null;
              });
            }
          }
        }, 1000);
      }
    },
    onend() {
      clearInterval(introActiveCheck);
    },
  });

  const mute = () => {
    if (muted === 'false') {
      window.Howler.mute(true);
      setMuted('true');
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Muted');
    } else {
      window.Howler.mute(false);
      setMuted('false');
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Unmuted');
    }
  };

  const loaderAnim = () => {
    const tl = new TimelineMax({
      onComplete: () => {
        const activeCheck = setInterval(() => {
          if (document.visibilityState === 'visible') {
            if (wakeLock !== null) {
              wakeLock.release().then(() => {
                wakeLock = null;
              });
            }
            setWorkoutStep(1);
            setLoader(false);

            return () => clearInterval(activeCheck);
          }
        }, 300);
      },
    });

    tl.to('.progress', {
      duration: 15,
      ease: Sine.easeOut,
      x: 0,
      delay: 0.5,
    }).to('.fade', 0.6, {
      opacity: 0,
      ease: Power0.easeInOut,
    });
  };

  useEffect(() => {
    introAudio.load();
    loaderAnim();
  }, []);

  useEffect(() => {
    if (wakeLock === null) requestWakeLock();
  }, []);

  useEffect(() => {
    const showCountdownTimeout = setTimeout(() => {
      const timer = setInterval(() => {
        if (document.visibilityState === 'visible') {
          setShowCountdown('true');
          clearInterval(timer);
        }
      }, 300);
    }, 11000);
    return () => clearTimeout(showCountdownTimeout);
  }, []);

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', 'Intro Loading', '/your-playlist');
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.Howler.unload();
    });
  }, []);

  return (
    <div className={`fade ${styles.loader} loader`}>
      <div>
        <p className="mute-icon" onClick={mute} onKeyDown={mute}>
          {muted === 'false' && <Svg src={Mute} />}
          {muted === 'true' && <Svg src={Muted} />}
        </p>
        {showCountdown === 'false' && (
          <div>
            <h1 className="color--green text--center push-v--mobile--small">Loading...</h1>
            <p className={`${styles.loader__text} color--green text--center`}>
              Please turn off silent mode and pump up your headphones and smart speakers.
            </p>
            <div className={styles.loader__progress}>
              <div className={`progress ${styles.loader__progressBar}`} />
            </div>
          </div>
        )}
        {showCountdown === 'true' && <Counter count={0} playlist={generatedPlaylist} />}
      </div>
    </div>
  );
};

export default Loader;
