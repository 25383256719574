import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import queryString from 'query-string';

import { AppContext } from '../utils/context';

function getUrlToken(query) {
  return queryString.parse(query);
}

const RouteValidator = ({ component: Component, location, data, cms }) => {
  const { connectToken, playlist, updateGlobalState } = useContext(AppContext);
  const { token } = getUrlToken(location.search);
  const isBrowser = typeof window !== 'undefined';
  let skip = null;

  if (isBrowser) {
    skip = localStorage.getItem('skip');
  }

  // // remove token from URL without redirecting
  if (token && !connectToken) {
    window.history.replaceState({}, document.title, '/your-playlist');

    if (!connectToken) {
      updateGlobalState({ connectToken: token });
    }
  }
  // // check we don't already have a connect token to avoid rendering loop
  // // if we're in browser and we have no token and no playlist then redirect to start
  if (isBrowser && !token && !playlist && skip === 'false') {
    // redirect to correct homepage
    navigate('/');
    return null;
  }
  const canRender = isBrowser && (connectToken || playlist || skip === 'true');
  return canRender && <Component token={token} location={location} data={data} cms={cms} />;
};

RouteValidator.defaultProps = { location: {} };

RouteValidator.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};

export default RouteValidator;
