import React, { useState, useEffect } from 'react';
import { Howl } from 'howler';
import sendDataLayer from '../../../utils/sendDataLayer';
import Exercise from './exercise';
import Cooldown from './cooldown';
import Playlist from './playlist';

const ContentIndex = ({ generatedPlaylist, cms }) => {
  const [step, setStep] = useState('exercise');
  const [count, setCount] = useState(0);
  const [muted, setMuted] = useState('false');
  const [durationExercise, setDurationExercise] = useState(30);
  const [durationRest, setDurationRest] = useState(15);
  const [exercise, setExercise] = useState('true');
  const playlist = generatedPlaylist.exercises;
  let activeCheck = null;
  let restActiveCheck = null;
  let wakeLock = null;
  const fadeTime = 1500;

  const requestWakeLock = async () => {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
    } catch (err) {
      console.log('wake-lock failed');
    }
  };

  const restTrack = new Howl({
    src: playlist[count].rest.audio,
    format: ['mp3'],
    preload: false,
    onload() {
      exerciseTrack.unload();
      restTrack.play();
    },
    onplay() {
      if (restActiveCheck === null) {
        restActiveCheck = setInterval(() => {
          updateWidth();

          if (document.visibilityState === 'visible') {
            if (!restTrack.playing() && wakeLock === null) {
              restTrack.play();
              requestWakeLock();
            }
          } else {
            restTrack.pause();

            if (wakeLock !== null) {
              wakeLock.release().then(() => {
                wakeLock = null;
              });
            }
          }
        }, 1000);
      }

      if (count + 1 === playlist.length) {
        setExercise('false');
        setStep('payoff');
        localStorage.setItem('payoff', 'true');
      }
    },
    onend() {
      setExercise('true');
      const time = 15 - restTrack.seek();
      setDurationRest(Math.round(time));
      updateWidth();
      if (count + 1 !== playlist.length) {
        setCount(count => count + 1);
      }
      clearInterval(restActiveCheck);
    },
  });

  const exerciseTrack = new Howl({
    src: playlist[count].track.audio,
    preload: false,
    format: ['mp3'],
    onload() {
      restTrack.unload();
      exerciseTrack.fade(0, 1, fadeTime);
      exerciseTrack.play();
    },
    onplay() {
      if (activeCheck === null) {
        activeCheck = setInterval(() => {
          updateWidth();
          if (document.visibilityState === 'visible') {
            if (!exerciseTrack.playing() && wakeLock === null) {
              exerciseTrack.play();
              requestWakeLock();
            }
          } else {
            exerciseTrack.pause();

            if (wakeLock !== null) {
              wakeLock.release().then(() => {
                wakeLock = null;
              });
            }
          }
        }, 300);
      }
    },
    onend() {
      setExercise('false');
      restTrack.load();
      clearInterval(activeCheck);
      const time = 30 - exerciseTrack.seek();
      setDurationExercise(Math.round(time));
      updateWidth();

      if (count + 1 !== playlist.length) {
        setStep('cooldown');
      }
    },
  });

  const updateWidth = () => {
    if (exerciseTrack.playing()) {
      const time = 30 - exerciseTrack.seek();
      setDurationExercise(Math.round(time));

      if (Math.round(time) === 2) {
        exerciseTrack.fade(1, 0, fadeTime);
      }
    } else if (restTrack.playing()) {
      const time = 15 - restTrack.seek();
      setDurationRest(Math.round(time));
    }
  };

  const mute = () => {
    if (muted === 'false') {
      window.Howler.mute(true);
      setMuted('true');
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Muted');
    } else {
      window.Howler.mute(false);
      setMuted('false');
      sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Unmuted');
    }
  };

  const exerciseFunction = () => {
    setStep('exercise');
    exerciseTrack.load();
  };

  useEffect(() => {
    if (wakeLock === null) requestWakeLock();
  }, []);

  useEffect(() => {
    exerciseFunction();
  }, [count]);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.Howler.unload();
    });
  }, []);

  return (
    <div>
      {step === 'exercise' && (
        <Exercise
          playlist={generatedPlaylist}
          duration={durationExercise}
          muted={muted}
          mute={mute}
          count={count}
          exercise={exercise}
        />
      )}
      {step === 'cooldown' && (
        <Cooldown
          playlist={generatedPlaylist}
          count={count}
          duration={durationRest}
          muted={muted}
          mute={mute}
          exercise={exercise}
        />
      )}
      {step === 'payoff' && <Playlist playlist={generatedPlaylist} cms={cms} />}
    </div>
  );
};

export default ContentIndex;
