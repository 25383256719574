/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
// import PropTypes from "prop-types"
import Cookies from 'js-cookie';
import gsap, { Sine } from 'gsap';
import styles from '../scss/components/cookie-bar.module.scss';

const CookieBar = () => {
  const [cookiesAccepted, setcookiesAccepted] = useState(!!Cookies.get('cookiesAccepted'));

  const updateCookies = event => {
    event.preventDefault();
    Cookies.set('cookiesAccepted', true, { expires: 365 });
    setcookiesAccepted(true);

    gsap.to('.cookie-bar', 0.4, {
      y: '100%',
      ease: Sine.easeInOut,
    });
  };

  const tempText =
    'We and our partners use cookies to personalize your experience, to show you ads based on your interests, and for measurement and analytics purposes. By using our website and our services, you agree to our use of cookies as described in our Cookie Policy.';

  useEffect(() => {
    if (!cookiesAccepted) {
      gsap.to('.cookie-bar', 0.4, {
        y: 0,
        ease: Sine.easeInOut,
      });
    }
  }, [cookiesAccepted]);

  return (
    <div className={styles.cookieBar}>
      <div className={styles.cookieBar__wrapper}>
        <p className={styles.cookieBar__text}>{tempText}</p>
        <button
          type="button"
          className={styles.cookieBar__button}
          onClick={e => updateCookies(e)}
          data-event-label="close cookie bar"
        >
          ok i understand
          {/* cms.cookiebar.button */}
        </button>
      </div>
    </div>
  );
};

// CookieBar.propTypes = { cms: PropTypes.objectOf(PropTypes.any) }

export default CookieBar;
