/**
 *  SEO component
 *  Because of how Gatsby generates the pages, this component won't work during dev
 *  In order to test this component out run 'yarn build' and then 'yarn serve'
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import uuid from 'uuid/v1';

const facebookPixel = [
  <script id="cidsyncjs" src="https://pixel-static.spotify.com/sync.min.js" async key={uuid()} />,
  <noscript key={uuid()}>
    {`
    <img
      id="cidmgmt"
      src="https://pixel.spotify.com/v1/sync?nojs=1"
      style="display: none;"
      width="0"
      height="0"
    />
  `}
  </noscript>,
];

function SEO({ title, description, pageUrl, twitterUsername }) {
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content:
            'Create a personalised HIIT workout and exercise with music you love. Try Spotify Pumped for free to pump up your fitness.',
        },
        {
          property: 'og:title',
          content: 'Free HIIT sessions with Spotify Pumped',
        },
        {
          property: 'og:description',
          content:
            'Create a personalised HIIT workout and exercise with music you love. Try Spotify Pumped for free to pump up your fitness.',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${process.env.GATSBY_DOMAIN_NAME}${pageUrl}`,
        },
        {
          property: 'og:image',
          content: `${process.env.GATSBY_DOMAIN_NAME}/social/facebook.jpg`,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: 'Free HIIT sessions with Spotify Pumped',
        },
        {
          name: 'twitter:site',
          content: `@${twitterUsername}`,
        },
        {
          name: 'twitter:description',
          content:
            'Create a personalised HIIT workout and exercise with music you love. Try Spotify Pumped for free to pump up your fitness.',
        },
        {
          property: 'twitter:image',
          content: `${process.env.GATSBY_DOMAIN_NAME}/social/twitter.jpg`,
        },
      ]}
    >
      {process.env.GATSBY_ENV === 'production' && facebookPixel}
    </Helmet>
  );
}

SEO.defaultProps = { description: '' };

SEO.propTypes = {
  description: PropTypes.string,
  twitterUsername: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SEO;
