/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Svg from 'react-inlinesvg';
import { Power0, TimelineMax } from 'gsap';
import sendDataLater from '../../../utils/sendDataLayer';

import styles from '../../../scss/components/progress-bar.module.scss';
import Arrow from '../../../images/svgs/icon_link_arrow.svg';

const ProgressBarIndex = ({ duration, playlist, count, exercise }) => {
  const checkTime = () => {
    let time;

    if (exercise === 'true') {
      time = 30;
    } else {
      time = 15;
    }

    return time;
  };

  const loaderAnim = () => {
    const tl = new TimelineMax({ onComplete: clearInterval(activeCheck) });

    tl.to('.progress', {
      duration: checkTime(),
      ease: Power0.easeInOut,
      x: 0,
      delay: 0.5,
    });

    const activeCheck = setInterval(() => {
      if (document.visibilityState === 'visible') {
        if (tl.pause()) {
          tl.play();
        }
      } else {
        tl.pause();
      }
    }, 500);
  };

  useEffect(() => {
    loaderAnim();
  }, []);

  return (
    <div className={styles.progress}>
      <div className={`${styles.progress__progressBar} progress`} />

      {exercise === 'true' && (
        <div>
          <img
            className={styles.progress__image}
            src={playlist.exercises[count].track.image}
            alt=""
          />
          <div className={styles.progress__lockup}>
            <p className={`${styles.progress__artist} color--white text--medium`}>
              <span className={styles.progress__trackName}>
                {playlist.exercises[count].track.artists}
              </span>
              <a
                onClick={event => {
                  event.preventDefault();
                  window.open(`${playlist.exercises[count].track.url}`);
                  sendDataLater(
                    'gtm.event',
                    'Interaction',
                    'Button Click',
                    `Open ${playlist.exercises[count].track.title} in spotify`,
                  );
                }}
                href="#"
              >
                <Svg className={styles.progress__icon} src={Arrow} />
              </a>
            </p>
            <p className={`${styles.progress__song} color--white text--medium`}>
              {playlist.exercises[count].track.title}
            </p>
          </div>
        </div>
      )}

      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      <p className={`${styles.progress__duration} h3 color--tangerine`}>{duration}s</p>
    </div>
  );
};

export default ProgressBarIndex;
